@import '@radix-ui/colors/blackA.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

/* reset */
button {
  all: unset;
}
/* 
.PopoverTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 5px 25px;
  font-size: 15px;
  line-height: 1;
  font-weight: 400;
  height: 35px;
  background-color: #99a090;
  color: var(--black);
  box-shadow: 0 2px 10px var(--blackA7);

}
.PopoverTrigger:hover {
  color: white;
  background-color: #3d4335;
}
.PopoverTrigger:focus {
  background-color: #3d4335;
  color: white;
} */

.PopoverContent {
  border-radius: 4px;
  padding: 15px;
  width: 300px;
  font-size: 15px;
  line-height: 1;
  color: black;
  background-color: #99a090;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
}
.PopoverContent:focus {
  outline: none;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px,
    0 0 0 2px var(--blackA7);
}

.PopoverContent {
  transform-origin: var(--radix-popover-content-transform-origin);
  animation: scaleIn 0.1s ease-out;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.PopoverArrow {
  fill: #99a090;
}