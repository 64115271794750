#popOver1:focus {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

#popOver2 {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

Popover {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

Popover.Panel {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

#map {
  filter: grayscale(1) contrast(1.2) opacity(0.30);
}

.link-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 250ms ease-in-out;
  color: white;
}

.link-underline-black {
  background-image: linear-gradient(transparent, transparent), linear-gradient(#3d4335, #3d4335);
}

.link-underline:hover {
  background-size: 100% 2px;
  background-position: 0 100%;
  color: #d5ddcb;
}

.fade-in-hero {
  animation: fadeInHero 5s;
  -webkit-animation: fadeInHero 5s;
  -moz-animation: fadeInHero 5s;
  -o-animation: fadeInHero 5s;
  -ms-animation: fadeInHero 5s;
}

.fade-in-text1 {
  animation: fadeIn1 3s;
  -webkit-animation: fadeIn1 3s;
  -moz-animation: fadeIn1 3s;
  -o-animation: fadeIn1 3s;
  -ms-animation: fadeIn1 3s;
}

.fade-in-text2 {
  animation: fadeIn2 6s;
  -webkit-animation: fadeIn2 6s;
  -moz-animation: fadeIn2 6s;
  -o-animation: fadeIn2 6s;
  -ms-animation: fadeIn2 6s;
}

.fade-in-text3 {
  animation: fadeIn3 5s;
  -webkit-animation: fadeIn3 5s;
  -moz-animation: fadeIn3 5s;
  -o-animation: fadeIn3 5s;
  -ms-animation: fadeIn3 5s;
}

@keyframes fadeInHero {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn1 {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn2 {
  0% {
      opacity: 0;
  }
  33% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
}

@keyframes fadeIn3 {
  0% {
      opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}





// @-moz-keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }

//   100% {
//     opacity: 1;
//   }
// }

// @-webkit-keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }

//   100% {
//     opacity: 1;
//   }
// }

// @-o-keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }

//   100% {
//     opacity: 1;
//   }
// }

// @-ms-keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }

//   100% {
//     opacity: 1;
//   }
// }

// #heroImg {
//   animation: fadeIn 6s;
// }

// @keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

